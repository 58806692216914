import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {Role} from "../../models/misc/role.enum";
import {Status, TransactionStatus, UserStatus} from "../../models/misc/status";

import {environment} from "../../../../environments/environment";


@Component({
  selector: 'app-default-table',
  templateUrl: './default-table.component.html',
  styleUrls: ['./default-table.component.scss'],
})
export class DefaultTableComponent implements OnInit {
  @Input() columns!: Column[];
  @Input() values!: Observable<any>;
  @Input() isEditable = false;
  @Input() isHideable = false;
  @Input() isDownloadable = false;
  @Input() isDeletable = false;
  @Input() isReplay = false;
  @Input() hasDetails = false;
  @Input() hasReport = false;
  @Input() hasVeriff = false;
  @Input() hasVeriffTrans = false;
  @Input() hasFilleuil = false;
  @Input() hasHide = false;
  @Input() isOnlyForRoot = false;
  @Input() page = 1;
  @Input() pageSize = 10;
  @Input() editRef!: any;
  @Input() downloadRef!: any;
  @Input() deleteRef!: any;
  @Input() idRef = 'id';

  @Output() onPageChangeEvent = new EventEmitter<number>();
  @Output() onEdit = new EventEmitter<ModalValue>();
  @Output() onDownload = new EventEmitter<ModalValue>();
  @Output() onDelete = new EventEmitter<ModalValue>();
  @Output() onReplay = new EventEmitter<ModalValue>();
  @Output() onHide = new EventEmitter<any>();
  @Output() onShowDetails = new EventEmitter<any>();
  @Output() onShowReport = new EventEmitter<any>();
  @Output() onShowVeriff = new EventEmitter<any>();
  @Output() onShowVeriffTrans = new EventEmitter<any>();
  @Output() onShowFilleuil = new EventEmitter<any>();

  hasOptions = false;
  generalStatus = Status;
  userStatus = UserStatus;
  transactionStatus = TransactionStatus;
  role = Role;

  firstColumn!: string;
  mColumns = new Array<Column>();

  base_url = environment.baseUrl;

  loadScript(url:any) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }



  ngOnInit(): void {

    this.firstColumn = this.columns[0].name;
    this.mColumns[0] = this.columns[0];
    for (let index = 0; index < this.columns.length; index++) {
      this.mColumns[index] = this.columns[index];
    }
    this.columns.shift();

    this.hasOptions =
      this.isEditable ||
      this.isDownloadable ||
      this.isDeletable ||
      this.isReplay ||
      this.isHideable ||
      this.hasDetails ||
      this.hasReport ||
      this.hasVeriff ||
      this.hasVeriffTrans ||
      this.hasFilleuil ||
      this.hasHide ||
      this.isOnlyForRoot;
  }

  /**
   * Event emit when page change
   * @param current current page
   */
  onPageChange(current: number) {
    this.onPageChangeEvent.emit(current);
  }

  createExcerpt(content: string, maxNumberOfWords: number){
    const listOfWords = content.trim().split(' ');
    const truncatedContent = listOfWords.slice(0, maxNumberOfWords).join(' ');
    const excerpt = truncatedContent + "...";
    return listOfWords.length > maxNumberOfWords ? excerpt : content;

  }


  /**
   * Download event
   * @param content a ref and an object
   */
  downloadInvoice(val: any) {
    this.onDownload.emit({ ref: this.downloadRef, content: val });
  }

  /**
   * Edit event
   * @param content a ref and an object
   */
  edit(val: any) {
    this.onEdit.emit({ ref: this.editRef, content: val });
  }

  /**
   * Delete Event
   * @param content a ref and an object
   */
  delete(val: any) {
    this.onDelete.emit({ ref: this.deleteRef, content: val });
  }

  /**
   *
   * @param val
   */
  hideOrShow(val: any) {
    this.onHide.emit(val);
  }

  showDetails(val: any) {
    this.onShowDetails.emit(val);
  }

  showReport(val: any) {
    this.onShowReport.emit(val);
  }

  replay(val: any) {
    this.onReplay.emit(val);
  }

  showVeriff(val: any) {
    this.onShowVeriff.emit(val);
  }

  showVeriffTrans(val: any) {
    this.onShowVeriffTrans.emit(val);
  }

  showFilleuil(val: any) {
    this.onShowFilleuil.emit(val);
  }

  download(fileName:string): string {
    return environment.url  + fileName;
  }


}

export class Column {
  constructor(public name: string, public propertyName: string) {}
}

export class ModalValue {
  constructor(public ref: any, public content: any) {}
}
