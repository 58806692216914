export enum Status {
  ACTIVE = 'ACTIF',
  BLOCKED = 'BLOQUE',

}

export enum UserStatus {
  //WAITING_VALIDATION = 'SUSPENDU',
  ACTIF = 'ACTIF',
  BLOQUE = 'BLOQUE',
}



export enum TransactionStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  PENDING = 'PENDING',
  PAYED = 'PAYED'

}
