import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';

// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnDestroy {

	private unsubscriber: Subject<any> = new Subject();
	public  screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	// Search Box
	public search: boolean = false;

	// Language
	public language: boolean = false;

	// Mega Menu
	public megaMenu: boolean = false;
	public levelMenu: boolean = false;
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

	// For Horizontal Layout Mobile
	public horizontal: boolean = window.innerWidth < 991 ? false : true;

	// Full screen
	public fullScreen: boolean = false;

	constructor(private router: Router) {
		this.setScreenWidth(window.innerWidth);
		fromEvent(window, 'resize').pipe(
			debounceTime(1000),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			}
			if(evt.target.innerWidth < 1199) {
				this.megaMenuColapse = true;
			}
		});
		if(window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			});
		}
	}

	ngOnDestroy() {
		this.unsubscriber.next(false);
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}

	MENUITEMS: Menu[] = [
    {
      headTitle1: 'Configuration', headTitle2: 'Administration SENDRATE',
    },
    {
      path: '/admin/dashboard', title: 'Tableau de bord', icon: 'home', type: 'link', bookmark: true, active: false
    },
    {
      title: 'Pays', icon: 'globe', type: 'sub', active: false, children: [
        { path: '#', title: 'Source', type: 'link', bookmark: true },
        { path: '/admin/countries', title: 'Destination', type: 'link', bookmark: true },
      ]
    },
    {
      path: '/admin/currencies', title: 'Devises', icon: 'check-square', type: 'link', bookmark: true, active: false
    },
    {
      path: '/admin/providers', title: 'Providers', icon: 'git-pull-request', type: 'link', bookmark: true, active: false
    },

    {
      path: '/admin/operations', title: 'Operations', icon: 'layers', type: 'link', bookmark: true, active: false
    },
    {
      path: '/admin/pricing-template', title: 'Plans Tarifaires', icon: 'package', type: 'link', bookmark: true, active: false
    },
    {
      path: '/admin/pricing', title: 'Tarifaires', icon: 'briefcase', type: 'link', bookmark: true, active: false
    },
    {
      path: '/admin/subscriptions', title: 'Abonnements', icon: 'shopping-bag', type: 'link', bookmark: true, active: false
    },
    {
      path: '/admin/exchange-rate', title: 'Taux de change', icon: 'maximize-2', type: 'link', bookmark: true, active: false
    },
    {
      path: '/admin/agencies', title: 'Agences', icon: 'shopping-bag', type: 'link', bookmark: true, active: false
    },

    {
      path: '/admin/cards', title: 'Gestion des cartes', icon: 'credit-card', type: 'link', bookmark: true, active: false
    },
    {
      path: '/admin/notifications', title: 'Notifications', icon: 'message-square', type: 'link', bookmark: true, active: false
    },
    {
      path: '/admin/faqs', title: 'FAQ', icon: 'help-circle', type: 'link', bookmark: true, active: false
    },
    {
      path: '/admin/transactions-code/ASK_FUND', title: 'Demandes de Fond', icon: 'phone-incoming', type: 'link', bookmark: true, active: false
    },
    {
      path: '/admin/beneficiaries', title: 'Béneficiaires', icon: 'user-plus', type: 'link', bookmark: true, active: false
    },
    {
      path: '/admin/sessions', title: 'Sessions', icon: 'user', type: 'link', bookmark: true, active: false
    },
    {
      title: 'Transactions', icon: 'bar-chart', type: 'sub', active: false, children: [
        { path: '/admin/transactions', title: `Historique des transactions`, type: 'link', bookmark: true },
        { path: '/admin/transactions-pending', title: `Transactions en attente`, type: 'link', bookmark: true },
        { path: '/admin/subscriptions-history', title: 'Historique des abonnements', type: 'link', bookmark: true },
      ]
    },
    {
      title: 'Paramètres', icon: 'settings', type: 'sub', active: false, children: [
        { path: '/admin/settings', title: `Global`, type: 'link', bookmark: true },
        { path: '/admin/configuration', title: 'Configuration', type: 'link', bookmark: true },
        { path: '/admin/services', title: 'Services', type: 'link', bookmark: true },
      ]
    },
    {
      title: 'Utilisateurs', icon: 'users', type: 'sub', active: false, children: [
        { path: '/admin/groups', title: 'Groupes', type: 'link', bookmark: true },
        { path: '/admin/users', title: 'Utilisateurs', type: 'link', bookmark: true },
      ]
    },


	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}
